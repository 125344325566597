import { SxProps } from '@mui/material';
import { lightTheme } from '../../theme/index';

const { palette, spacing } = lightTheme;

const fontTitleMortgagesPages = {
  color: palette.grey['50'],
  width: '100%',
  fontWwight: 700
};

const imgContainerSimple = {
  position: 'absolute',
  top: '0',
  display: 'block',
  width: '100%',
  height: '100%'
};

const paragragh = {
  lineHeight: 'normal',
  color: palette.grey['50'],
  marginBottom: spacing(0.5),
  width: '100%',
  // TODO: Need to check ripple effect of changing display from 'flex' to 'block'
  display: 'block',
  alignItems: 'center',
  flexWrap: 'wrap',
  img: {
    marginLeft: { xs: '0', md: '0.25rem' },
    height: '2rem',
    marginBottom: '-0.2rem'
  },
  '&:last-child': {
    marginBottom: 0
  }
};

const styles: Record<string, SxProps> = {
  mainBox: {
    overflow: {
      xs: 'hidden',
      lg: 'initial'
    },
    position: 'relative',
    marginTop: '5.625rem',
    paddingY: spacing(4),
    '.bg-default': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0
    },

    '& .anchorLinks': {
      color: palette.grey['50']
    },

    '.hero-btn': {
      textDecoration: 'none',
      width: {
        xs: '100%',
        md: 'auto'
      }
    }
  },
  greenBox: {
    overflow: 'hidden',
    backgroundColor: palette.lightishAqua['100']
  },

  link: {
    color: palette.grey['50'],
    paddingRight: {
      md: '0.25rem'
    },
    paddingLeft: {
      md: '0.25rem'
    }
  },

  layoutContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'relative',
    zIndex: 2,
    height: '100%',
    justifyContent: 'center',
    paddingLeft: {
      sm: spacing(4)
    },
    wordBreak: 'break-word',
    background: 'none'
  },
  layoutContainerBlue: {
    justifyContent: {
      xs: 'center',
      md: 'flex-end',
      lg: 'center'
    },
    marginTop: {
      xs: '12rem',
      md: '2rem',
      lg: '0'
    },
    background: 'none'
  },

  internalLayout: {
    maxWidth: {
      xs: '100%',
      sm: '80vw'
    },
    width: { xs: '100%', sm: 'fit-content' },
    padding: spacing(2),
    background: 'rgba(0, 0, 0, 0.5)'
  },
  internalLayoutBlue: {
    background: 'none',
    maxWidth: '30rem'
  },

  titleText: {
    marginBottom: spacing(1)
  },
  titleTextNormal: {
    ...fontTitleMortgagesPages
  },
  titleTextBlue: {
    ...fontTitleMortgagesPages,
    color: palette.primary.main
  },

  subheadingText: {
    ...paragragh,
    width: '100%'
  },
  subheadingTextBlue: {
    color: palette.grey.main
  },

  subtitleText: {
    ...paragragh
  },

  subtitleTextBlue: {
    color: palette.grey.main
  },

  paragraphText: {
    ...paragragh
  },

  paragraphTextBlue: {
    color: palette.grey.main
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: spacing(1),
    marginTop: spacing(2),
    width: {
      xs: '100%',
      sm: 'auto'
    },
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    '> a, button': {
      width: {
        xs: '100%',
        md: 'auto'
      }
    }
  },
  heroBtn: {
    textTransform: 'none',
    width: {
      xs: '100%',
      md: 'auto'
    }
  },
  btnLink: {
    textDecoration: 'none',
    width: {
      xs: '100%',
      md: 'auto'
    }
  },

  bgImgNormal: {
    height: {
      xs: '33rem',
      md: '45rem'
    },
    backgroundPosition: {
      xs: '65% 0',
      md: '60%',
      lg: 'center',
      lg: 'right'
    },
    backgroundSize: {
      xs: 'cover',
      md: 'cover'
    }
  },
  bgImgNormalVar: {
    ...imgContainerSimple,
    left: '0'
  },
  bgImageStyles: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  bgMyFoxtonsVar: {
    ...imgContainerSimple,
    right: '0',
    width: {
      xs: '100%',
      md: '100%',
      lg: '55%',
      xl: '50%'
    },
    '& img': {
      width: {
        xs: '100%',
        md: '100%',
        lg: '100%'
      },
      height: {
        xs: 'auto',
        md: '30rem',
        lg: 'auto',
        lg: '100%'
      },
      objectFit: 'contain',
      position: 'absolute',
      right: '0'
    }
  }
};

export default styles;

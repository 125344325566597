import Head from 'next/head';

type BannerImageProps = {
  backgroundSizes: {
    large: string;
    medium: string;
    small: string;
  };
  altText: string;
  onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  blurDataURI: string;
};

function BannerImage(props: BannerImageProps) {
  const { backgroundSizes, onError, altText, blurDataURI } = props;

  const blurPhotoStyles = {
    color: 'transparent',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cimage style='filter:blur(20px)' preserveAspectRatio='none' x='0' y='0' height='100%25' width='100%25' href='${blurDataURI}'/%3E%3C/svg%3E")`
  };

  return (
    <>
      <Head>
        <link
          rel="preload"
          href={backgroundSizes?.small}
          as="image"
          media="(max-width: 599px)"
          fetchPriority="high"
        />
        <link
          rel="preload"
          href={backgroundSizes?.medium}
          as="image"
          media="(min-width: 600px) and (max-width: 1199px)"
          fetchPriority="high"
        />
        <link
          rel="preload"
          href={backgroundSizes?.large}
          as="image"
          media="(min-width: 1200px)"
          fetchPriority="high"
        />
      </Head>

      <picture>
        <source
          srcSet={backgroundSizes?.large || ''}
          media="(min-width: 1200px)"
        />
        <source
          srcSet={backgroundSizes?.medium || ''}
          media="(min-width: 600px)"
        />
        <img
          src={backgroundSizes?.small || ''}
          alt={altText}
          style={blurPhotoStyles}
          onError={onError}
        />
      </picture>
    </>
  );
}

export default BannerImage;

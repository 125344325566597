import { SxProps } from '@mui/material';
import styles from './styles';

export const getBackgroundStyles = (variant: 'normal' | 'blue' | 'small') => {
  const backgroundSection: Record<string, SxProps> = {
    normal: {
      ...styles.mainBox
    },
    small: {
      ...styles.mainBox
    },
    blue: {
      ...styles.mainBox,
      ...styles.greenBox,
      height: 'calc(100vh - 5.5rem)'
    }
  };

  return { ...backgroundSection[variant] };
};

export const getBgStyles = (
  variant: 'normal' | 'blue' | 'small',
  imagePlacementOnMobile = 'center'
) => {
  const bgImg: Record<string, SxProps> = {
    normal: {
      ...styles.bgImgNormalVar
    },
    small: {
      ...styles.bgImgNormalVar
    },
    blue: {
      ...styles.bgMyFoxtonsVar
    }
  };

  return {
    ...bgImg[variant],
    '& img': {
      ...styles.bgImageStyles,
      objectPosition: {
        xs: imagePlacementOnMobile
      }
    }
  };
};

export const getFontTitleStyles = (variant: 'normal' | 'blue' | 'small') => {
  const fontTitle: Record<string, SxProps> = {
    normal: {
      ...styles.titleTextNormal
    },
    small: {
      ...styles.titleTextNormal
    },
    blue: {
      ...styles.titleTextBlue
    }
  };

  return { ...styles.titleText, ...fontTitle[variant] };
};

export const getFontSubtitleStyles = (variant: 'normal' | 'blue' | 'small') => {
  const fontSubtitle: Record<string, SxProps> = {
    normal: {
      ...styles.subtitleText
    },
    small: {
      ...styles.subtitleText
    },
    blue: {
      ...styles.subtitleText,
      ...styles.subtitleTextBlue
    }
  };

  return { ...fontSubtitle[variant] };
};

export const getFontSubheadingStyles = (
  variant: 'normal' | 'blue' | 'small'
) => {
  const fontSubheading: Record<string, SxProps> = {
    normal: {
      ...styles.subheadingText
    },
    small: {
      ...styles.subheadingText
    },
    blue: {
      ...styles.subheadingText,
      ...styles.subheadingTextBlue
    }
  };

  return { ...fontSubheading[variant] };
};

export const getParagraphStyles = (variant: 'normal' | 'blue' | 'small') => {
  const fontParagraph: Record<string, SxProps> = {
    normal: {
      ...styles.paragraphText
    },
    small: {
      ...styles.paragraphText
    },
    blue: {
      ...styles.paragraphText,
      ...styles.paragraphTextBlue
    }
  };

  return { ...fontParagraph[variant] };
};

export const getTextBoxStyles = (variant: 'normal' | 'blue' | 'small') => {
  const boxStyles: Record<string, SxProps> = {
    normal: {
      ...styles.internalLayout
    },
    small: {
      ...styles.internalLayout
    },
    blue: {
      ...styles.internalLayout,
      ...styles.internalLayoutBlue
    }
  };

  return { ...boxStyles[variant] };
};

export const getTextGridStyles = (variant: 'normal' | 'blue' | 'small') => {
  const gridStyles: Record<string, SxProps> = {
    normal: {
      ...styles.layoutContainer
    },
    small: {
      ...styles.layoutContainer
    },
    blue: {
      ...styles.layoutContainer,
      ...styles.layoutContainerBlue
    }
  };

  return { ...gridStyles[variant] };
};

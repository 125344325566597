import React, { useState } from 'react';
import { Box, Typography, Grid } from '@mui/material/';
import Link from 'next/link';
import Image from 'next/image';
import Markdown from 'markdown-to-jsx';
import { Button, LibButtonProps } from '../../components/Button';
import BannerImage from './BannerImage';
import {
  getBackgroundStyles,
  getBgStyles,
  getFontTitleStyles,
  getFontSubheadingStyles,
  getFontSubtitleStyles,
  getParagraphStyles,
  getTextBoxStyles,
  getTextGridStyles
} from './utility';
import styles from './styles';
import useClientOnly from '../../utils/customHooks/useClientOnly';
import markdownOverrides from '../../utils/MarkdownOverrides';
import { getUserFromStorage } from '../../utils';
import {
  dataURIGravel,
  fallbackImageUrl
} from '../../utils/Constants/fallbackImageUrl';
import { dynamicImport } from '../../utils/reactUtils';

const Alert = dynamicImport(() => import('../../components/Alert'));

export interface LibHeroBannerProps {
  richTextContent?: string;
  btnPrimary?: null | {
    label: string;
    href: string;
    colorProp: 'primary' | 'secondary' | 'tertiary' | 'text';
    target?: string;
    variant?: LibButtonProps['variant'];
  };
  btnSecondary?: null | {
    label: string;
    href: string;
    colorProp: 'primary' | 'secondary' | 'tertiary' | 'text';
    target?: string;
    variant?: LibButtonProps['variant'];
  };
  background?: null | {
    img: string;
    altText: string;
  };
  backgroundSizes?: null | {
    large: { url: string };
    medium: { url: string };
    small: { url: string };
    smallportrait: { url: string };
  };
  token?: boolean;
  myfoxtonsHref?: string;
  publicShareKey?: string;
  variant?: 'normal' | 'blue' | 'small';
  imagePlacementOnMobile?: 'left' | 'right' | 'center';
  fallBackImageUri?: string;
}

const auth = ['auth/login', 'auth/registration'];

const isAuthBtn = (href: string) =>
  auth.some((item) => href.toLowerCase().indexOf(item) > -1);

const goToMyFoxtons = 'Go To My Foxtons';

function LibHeroBanner({
  richTextContent,
  btnPrimary,
  btnSecondary,
  background,
  backgroundSizes,
  token,
  myfoxtonsHref,
  publicShareKey = '',
  variant = 'normal',
  imagePlacementOnMobile = 'center',
  fallBackImageUri
}: LibHeroBannerProps) {
  const { hasMounted } = useClientOnly();
  const { user } = getUserFromStorage();
  const isLoggedIn = token || user?.isLoggedIn;
  const [hasError, setHasError] = useState(false);

  const [notification, setNotification] = useState<Notification>({
    hasNotification: false,
    notificationContent: '',
    notificationType: 'success'
  });

  const isPrimaryAuthLoggedIn =
    isLoggedIn && isAuthBtn(btnPrimary?.href || '') && hasMounted;
  const isSecondaryAuthLoggedIn =
    isLoggedIn && isAuthBtn(btnSecondary?.href || '') && hasMounted;

  const overrides = {
    h1: {
      component: Typography,
      props: {
        variant: 'h1',
        sx: getFontTitleStyles(variant)
      }
    },
    h2: {
      component: Typography,
      props: {
        variant: 'h2',
        sx: getFontSubtitleStyles(variant)
      }
    },
    h3: {
      component: Typography,
      props: { variant: 'h3', sx: getFontSubheadingStyles(variant) }
    },
    h4: {
      component: Typography,
      props: { variant: 'h4', sx: getFontSubheadingStyles(variant) }
    },
    p: {
      component: Typography,
      props: { variant: 'body1', sx: getParagraphStyles(variant) }
    },
    a: {
      component: markdownOverrides.a.component,
      props: {
        ...markdownOverrides.a.props,
        style: styles.link
      }
    },
    strong: {
      component: Typography,
      props: { variant: 'heavy', sx: styles.strong }
    }
  };

  const copyClipboard = async () => {
    const basePath = window.location.host;

    const stringToCopy = `${basePath}/education/shortlist?share_key=${publicShareKey}`;

    try {
      const permissions = await navigator.permissions.query({
        name: 'clipboard-write'
      });

      if (permissions.state === 'granted' || permissions.state === 'prompt') {
        await navigator.clipboard.writeText(stringToCopy);

        setNotification({
          hasNotification: true,
          notificationContent: 'Copied to clipboard',
          notificationType: 'success'
        });
      } else {
        throw new Error(
          "Can't access the clipboard. Check your browser permissions."
        );
      }
    } catch (error) {
      setNotification({
        hasNotification: true,
        notificationContent: 'Can not be copied to clipboard',
        notificationType: 'error'
      });
    }
  };

  const getPrimaryButton = () => {
    if (btnPrimary) {
      if (btnPrimary.variant?.indexOf('switch-lang-') > -1) {
        return (
          <Button variant={btnPrimary.variant}>{btnPrimary?.label}</Button>
        );
      }

      if (btnPrimary.href.startsWith('component:')) {
        return (
          <Button
            color="primary"
            variant={btnPrimary.colorProp}
            sx={styles.heroBtn}
            href={btnPrimary.href}
          >
            {btnPrimary.label}
          </Button>
        );
      }

      return (
        <Link
          className="hero-btn"
          prefetch={false}
          href={isPrimaryAuthLoggedIn ? myfoxtonsHref : btnPrimary?.href || ''}
          target={btnPrimary?.target || ''}
        >
          <Button
            color="primary"
            variant={btnPrimary.colorProp}
            sx={styles.heroBtn}
          >
            {isPrimaryAuthLoggedIn ? goToMyFoxtons : btnPrimary.label}
          </Button>
        </Link>
      );
    }

    return null;
  };

  const getSecondaryButton = () => {
    if (btnSecondary?.variant?.indexOf('switch-lang-') > -1) {
      return (
        <Button variant={btnSecondary?.variant}>{btnPrimary?.label}</Button>
      );
    }

    if (btnSecondary?.label === 'Copy URL') {
      return (
        <Button variant="secondary" onClick={copyClipboard}>
          {btnSecondary?.label}
        </Button>
      );
    }

    if (isPrimaryAuthLoggedIn && isSecondaryAuthLoggedIn) {
      return null;
    }

    if (btnSecondary?.href?.startsWith('component:')) {
      return (
        <Button
          color="primary"
          variant={btnSecondary?.colorProp}
          sx={styles.heroBtn}
          href={btnSecondary.href}
        >
          {btnSecondary?.label}
        </Button>
      );
    }

    return (
      <Link
        className="hero-btn"
        prefetch={false}
        href={
          isSecondaryAuthLoggedIn ? myfoxtonsHref : btnSecondary?.href || ''
        }
        target={btnSecondary?.target}
      >
        <Button
          color="primary"
          variant={btnSecondary?.colorProp}
          sx={styles.heroBtn}
        >
          {isSecondaryAuthLoggedIn ? goToMyFoxtons : btnSecondary?.label}
        </Button>
      </Link>
    );
  };

  const fallbackImage = fallBackImageUri ?? fallbackImageUrl;

  return (
    <Box sx={getBackgroundStyles(variant)} id="heroBanner" component="section">
      {/* AlertBox */}
      <Box sx={{ position: 'relative' }}>
        <Alert
          open={notification.hasNotification}
          severity={notification.notificationType}
          title={notification.notificationType}
          description={notification.notificationContent}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          onClose={() =>
            setNotification((prevState) => ({
              ...prevState,
              hasNotification: false
            }))
          }
          autoHideEnable
          autoHideAfter={2000}
        />
      </Box>

      <Box sx={getBgStyles(variant, imagePlacementOnMobile)}>
        {!backgroundSizes ? (
          <Image
            src={
              hasError
                ? fallBackImageUri ?? fallBackImageUrl
                : background?.img || ''
            }
            blurDataURL={dataURIGravel}
            placeholder="blur"
            sizes="100vw"
            alt={background?.altText || ''}
            width={0}
            height={0}
            priority
            unoptimized
            onError={() => setHasError(true)}
          />
        ) : hasError === false ? (
          <BannerImage
            backgroundSizes={{
              large: backgroundSizes?.large?.url || '',
              medium: backgroundSizes?.medium?.url || '',
              small: backgroundSizes?.smallportrait?.url || ''
            }}
            altText={background?.altText || ''}
            onError={() => setHasError(true)}
            blurDataURI={dataURIGravel}
          />
        ) : (
          <img src={fallbackImage} alt={background?.altText || ''} />
        )}
      </Box>

      <Grid container sx={getTextGridStyles(variant)}>
        <Box sx={getTextBoxStyles(variant)}>
          <Box>
            {richTextContent ? (
              <Markdown options={{ overrides }}>{richTextContent}</Markdown>
            ) : null}
          </Box>
          {(btnPrimary?.label || btnSecondary?.label) && (
            <Box sx={styles.buttonContainer}>
              {getPrimaryButton()}
              {btnSecondary?.label && getSecondaryButton()}
            </Box>
          )}
        </Box>
      </Grid>
    </Box>
  );
}

export const HeroBanner = React.memo(LibHeroBanner);

export default HeroBanner;
